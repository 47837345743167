import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { get, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import utc from 'dayjs/plugin/utc';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import DataTable from '../../components/table/VitrulisedTable';
import { Types } from '../../types';
import FilterModal from '../admin/element/FilterModal';
import { getProjectFieldUsers } from '../projectList/fieldUser/fieldUserSlice';
import { getLocations } from '../projectList/location/locationSlice';
import { getRoles } from '../projectList/roles/rolesSlice';
import { getFieldUsers } from '../user/field/fieldUserSlice';
import SingleStudy from './SingleStudy';
import { downloadSelectedStudy, getStudies, markStudyReviewed, updateSingleStudy } from './studiesSlice';

dayjs.extend(AdvancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

const StudyPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const todayStart = dayjs().startOf('day').toISOString();
  const todayEnd = dayjs().endOf('day').toISOString();
  const { studies, isLoading, singleStudy } = useSelector((state) => state.studies);
  const { projectFieldUsers } = useSelector((state) => state.projectUsers);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { singleProjectData } = useSelector((state) => state.project);
  const [sortBy, setSortBy] = useState('');
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';
  const getTimezoneAbbreviation = (timeZone) => moment.tz(timeZone).zoneAbbr();
  const openFilter = (key, orderBy, isLabelNumeric, sortType) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
    if (isLabelNumeric) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: true }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: false }));
    }
    if (sortType) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType: 'string' }));
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const renderOsInfo = (os) => {
    if (os === 'android') {
      return <Iconify icon="flat-color-icons:android-os" />;
    }
    if (os === 'ios') {
      return <Iconify icon="devicon:apple" />;
    }
    return (
      <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
        Other
      </Typography>
    );
  };

  const defaultHeadCells = useMemo(
    () => [
      {
        id: 'name',
        numeric: false,
        label: 'Study Name',
        // minWidth: 160,
        align: 'left',
        onClick: () => openFilter('name', 'name'),
      },
      {
        id: 'locationName',
        numeric: false,
        label: 'Location',
        onClick: () => openFilter('locationName', 'locationName'),
      },
      {
        id: 'userName',
        disablePadding: false,
        label: 'F/User',
        onClick: role === '3' ? null : () => openFilter('userName', 'userName'),
      },
      {
        id: 'studyStartTime',
        label: 'Date/Timing',
        renderElement: (cell) => (
          <Stack direction="column">
            <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
              {cell?.studyStartTime}
            </Typography>
            <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
              {cell?.startHours} - {cell?.endHours}
            </Typography>
          </Stack>
        ),
        onClick: () => openFilter('studyStartTime', 'studyStartTime', false, 'date'),
        // onClick: () => openFilter('studyStart', 'studyStart', true),
      },
      {
        id: 'osInfo',
        disablePadding: false,
        label: 'OS Info',
        // minWidth: 70,
        align: 'start',
        renderElement: (cell) => (
          <Stack maxWidth={70} direction="row" alignItems="center" justifyContent="flex-start">
            {renderOsInfo(cell?.osInfo)}
            <Typography sx={{ fontSize: '0.9375rem', pl: 0 }} variant="captions">
              {cell?.version}
            </Typography>
          </Stack>
        ),
        onClick: () => openFilter('osInfo', 'osInfo'),
      },
      {
        id: 'elapsedTime',
        disablePadding: false,
        label: 'Elapsed Time',
        // minWidth: 150,
        onClick: () => openFilter('elapsedTime', 'elapsedTime', true),
      },
      {
        id: 'inFo',
        disablePadding: false,
        label: 'Info',
      },
      {
        id: 'isFieldUserReviewed',
        // disablePadding: false,
        label: 'F/User Reviewed',
        // minWidth: 190,
        align: 'center',
        renderElement: (cell) =>
          cell?.isFieldUserReviewed && (
            <Box sx={{ pl: 0 }}>
              <CheckCircleOutlineOutlinedIcon sx={{ ml: 0 }} fontSize="small" color="success" />
            </Box>
          ),
      },
      {
        id: 'isReviewed',
        disablePadding: false,
        label: 'Reviewed',
        align: 'centre',
        renderElement: (cell) =>
          cell?.isReviewed && (
            <Box sx={{ pl: 1 }}>
              <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
            </Box>
          ),
      },
      {
        id: 'studyType',
        disablePadding: false,
        label: 'Study Type',
        // minWidth: 180,
        renderElement: (cell) => (
          <Typography sx={{ color: getStudyColor(cell.studyType) }} variant="subtitle2">
            {getStudyLabel(cell.studyType)}
          </Typography>
        ),
        onClick: () => openFilter('studyType', 'studyType'),
      },
    ],
    [role]
  );
  const headCells = useMemo(() => {
    if (!!role && (role === '1' || role === '2')) {
      return defaultHeadCells;
    }
    return defaultHeadCells.filter((cell) => cell?.id !== 'isReviewed');
  }, [defaultHeadCells, role]);
  // Initial state with startDate and endDate
  const [dates, setDates] = useState({
    startDate: dayjs().subtract(5, 'days').startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString(),
  });
  const [filterBy, setFilterBy] = useState('');
  const [filterConfig, setFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const [filterOptions, setFilterOptions] = useState([]);
  const getInfoName = (studyType, study) => {
    if (studyType === 2) {
      return study?.roleName;
    }
    if (studyType === 1 && study?.roundDuration) {
      const convertedRoundDuration = dayjs.duration(study?.roundDuration).asMinutes();
      return `${convertedRoundDuration} mins`;
    }
    return '';
  };
  const studynameOptions = useMemo(() => {
    if (studies && studies?.length) {
      const uniqueStudyNames = [...new Set(studies.map((studyRow) => studyRow.name))];
      return uniqueStudyNames.sort((a, b) => a.localeCompare(b)).map((studyName) => ({ label: studyName, value: studyName }));
    }
    return [];
  }, [studies]);

  const fetchStudies = () => {
    dispatch(getStudies({ projectID, ...dates }));
  };
  useEffect(() => {
    if (projectID) {
      dispatch(getFieldUsers({ projectID }));
      dispatch(getProjectFieldUsers({ projectID }));
      dispatch(getRoles({ projectID }));
      // dispatch(getTasks({ projectID }));
      // dispatch(getElements({ projectID, defaultEntry: false }));
      dispatch(getLocations({ projectID }));
    }
  }, [dispatch, projectID]);
  useEffect(() => {
    if (projectID) {
      dispatch(getStudies({ projectID, ...dates }));
    }
  }, [dates, dispatch, projectID]);

  const { fieldUsers } = useSelector((state) => state?.fieldUser);
  const { locations } = useSelector((state) => state.location);
  const { roles } = useSelector((state) => state?.roles);
  const studyData = useMemo(
    () =>
      studies?.length > 0
        ? studies.map((study) => {
            // const location = locations.find((element) => element?._id === study.locationID)?.name;
            const location = get(study, 'locationID', '');
            const locationName = get(study, 'locationName', '') || '';
            const user = get(study, 'userID', '');
            const fieldUser = get(study, 'userID', '');
            // const fieldUser = `${user?.name || ''}  ${user?.lastName || ''}`;
            const start = get(study, 'studyStartTime', '');
            const end = get(study, 'studyEndTime', '');
            const timeZone = get(study, 'timeZone', '') || singleProjectData?.timezone || 'Europe/London';
            // const studyStartTime = start && dayjs.tz(start, timeZone)?.format('DD/MM/YYYY zzz');
            const studyStartTime = start && moment.tz(start, timeZone).format(`DD/MM/YYYY z`);
            const studyStart = start && moment.tz(start, timeZone)?.format('DD/MM/YYYY');
            const startHours = start && moment.tz(start, timeZone)?.format('HH:mm');
            const studyTypes = get(study, 'projectSetting.studyTypes', []);
            const inFo = getInfoName(get(study, 'studyType'), study);
            const endHours = end && moment.tz(end, timeZone)?.format('HH:mm');
            const studyEndTime = end && moment.tz(end, timeZone)?.format('DD/MM/YYYY Z');
            const endTime = dayjs(end);
            const startTime = dayjs(start);
            const diffHours = endTime.diff(startTime, 'hour');
            const diffMinutes = endTime.diff(startTime, 'minute') % 60;
            const elapsedTime = `${diffHours} hrs ${diffMinutes} mins`;
            const isDuplicateStudy = studies.some((currentStudy) => currentStudy?.name === study?.name && currentStudy !== study);
            // return { ...study, location, fieldUser, elapsedTime };
            return {
              ...study,
              userName: study?.userName || '',
              studyTypes,
              locationName,
              studyStart,
              location,
              fieldUser,
              studyEndTime,
              studyStartTime,
              startHours,
              endHours,
              elapsedTime,
              inFo,
              isDuplicateStudy,
            };
          })
        : [],
    [singleProjectData?.timezone, studies]
  );
  const LocationOptions = useMemo(() => {
    if (locations && !!locations?.length) {
      return locations.map((location) => ({ label: location?.name, value: location?._id }));
    }
    return [];
  }, [locations]);
  const locationFilterOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      const uniqueLocationNames = [...new Set(studyData.map((studyRow) => (studyRow.locationName ? studyRow.locationName : '')))];
      return uniqueLocationNames
        .sort((a, b) => a.localeCompare(b))
        .map((locationName) =>
          locationName ? { label: locationName, value: locationName } : { label: 'No Location', value: '' }
        );
    }
    return [];
  }, [studyData]);
  const fieldUserOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      const uniqueUserNames = [...new Set(studyData.map((studyRow) => (studyRow.userName ? studyRow.userName : '')))];
      return uniqueUserNames
        .sort((a, b) => a.localeCompare(b))
        .map((userName) => (userName ? { label: userName, value: userName } : { label: 'No User', value: '' }));
    }
    return [];
  }, [studyData]);
  const roleOptions = useMemo(() => {
    if (roles && !!roles?.length) {
      return roles
        ?.filter((currentRole) => currentRole?.projectSetting?.studyTypes?.includes(2))
        ?.map((currentRole) => ({ label: currentRole?.name, value: currentRole?._id }));
    }
    return [];
  }, [roles]);

  // const dateA = dayjs(aValue, 'DD/MM/YYYY');
  // const dateB = dayjs(bValue, 'DD/MM/YYYY');
  // return dateB - dateA;

  const dateOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      // Extract unique startTime values using Set to avoid duplicates
      // const uniqueStartTimes = [...new Set(studyData.map((study) => study.studyStart))];
      const uniqueStartTimes = [...new Set(studyData.map((study) => study.studyStartTime))];
      // Map them to the required format
      return uniqueStartTimes
        .sort((a, b) => dayjs(a, 'DD/MM/YYYY') - dayjs(b, 'DD/MM/YYYY'))
        .map((startTime) => ({ label: startTime, value: startTime }));
    }
    return [];
  }, [studyData]);
  const osInfoOptions = useMemo(() => {
    if (studies && studies?.length) {
      const uniqueOsInfo = [...new Set(studies.map((study) => study.osInfo))];
      return uniqueOsInfo.map((osInfo) => ({ label: startCase(osInfo), value: osInfo }));
    }
    return [];
  }, [studies]);
  const elapsedTimeOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      const uniqueElapsedTime = [...new Set(studyData.map((study) => study.elapsedTime))];
      return uniqueElapsedTime.map((osInfo) => ({ label: osInfo, value: osInfo }));
    }
    return [];
  }, [studyData]);
  // const fieldUserOptions = useMemo(() => {
  //   if (projectFieldUsers && projectFieldUsers?.length) {
  //     return projectFieldUsers
  //       ?.filter((user) => !!user?.name)
  //       ?.map((fieldUser) => ({
  //         label: `${get(fieldUser, 'name', '')} ${get(fieldUser, 'lastname', '')}`,
  //         value: `${get(fieldUser, 'name', '')} ${get(fieldUser, 'lastname', '')}`,
  //       }));
  //   }
  //   return [];
  // }, [projectFieldUsers]);

  const studyOptions = useMemo(
    () => [
      { label: 'Activity Study', value: 3 },
      { label: 'Efficiency Study', value: 1 },
      { label: 'Role Study', value: 2 },
      // { label: 'Predetermined Study', value: 4 },
    ],
    []
  );
  useEffect(() => {
    switch (filterBy) {
      case 'name':
        setFilterOptions(studynameOptions);
        break;
      case 'userName':
        setFilterOptions(fieldUserOptions);
        break;
      case 'locationName':
        setFilterOptions(locationFilterOptions);
        break;
      case 'studyStart':
        setFilterOptions(dateOptions);
        break;
      case 'studyStartTime':
        setFilterOptions(dateOptions);
        break;
      case 'osInfo':
        setFilterOptions(osInfoOptions);
        break;
      case 'elapsedTime':
        setFilterOptions(elapsedTimeOptions);
        break;
      case 'studyType':
        setFilterOptions(() =>
          studyOptions?.filter(
            (option) => studyData?.length > 0 && studyData?.some((study) => study?.studyType === option?.value)
          )
        );
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    filterBy,
    studynameOptions,
    dateOptions,
    osInfoOptions,
    elapsedTimeOptions,
    studyOptions,
    LocationOptions,
    locationFilterOptions,
    studyData,
    fieldUserOptions,
  ]);

  const handleRowClick = (event, row) => row && dispatch(updateSingleStudy(row));
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  const nestedRowProps = {
    LocationOptions,
    fetchStudies,
    roleOptions,
  };
  const handleReviewClicked = (selected, handleClearSelection) => {
    if (selected?.length > 0) {
      const studiesToReview = studyData
        .filter((study) => selected.includes(study._id))
        .map((study) => ({
          projectID,
          studyID: study?._id,
          studyType: study?.studyType,
        }));
      const payload = { studiesData: studiesToReview, role };
      dispatch(markStudyReviewed(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Reviewed Successfully'),
              })
            );
            handleClearSelection();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const getStudiesPayload = (selectedRows) => {
    if (selectedRows && selectedRows.length > 0) {
      const studiesData = studyData
        .filter((study) => selectedRows.includes(study._id)) // Filter studies with matching IDs
        .map((study) => ({
          studyID: study._id,
          studyType: study.studyType,
        }));

      return { studiesData };
    }
    return { studiesData: [] }; // Return empty array if no rows are selected
  };
  const toolbarProps = {
    handleReviewClicked,
    getStudiesPayload,
    fetchStudies,
    dates,
    setDates,
  };
  const downloadStudyRow = async (row, event) => {
    if (row) {
      const payload = {
        studyType: get(row, 'studyType'),
        studyIDs: [get(row, '_id')],
        projectID: get(singleProjectData, '_id'),
        customerID: get(singleProjectData, 'customerID'),
        projectName: get(singleProjectData, 'name'),
        customerName: get(singleProjectData, 'customerName'),
      };
      dispatch(downloadSelectedStudy(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
            dispatch(getStudies({ projectID, ...dates }));
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  return (
    <div>
      {/* {!singleStudy ? ( */}
      <Box sx={{ display: singleStudy ? 'none' : 'block' }}>
        <ModalComponent
          persist
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: '600px !important',
              maxHeight: '700px !important',
            },
          }}
          title="Filter"
          open={isFilterOpen}
          onClose={setIsFilterOpen}
        >
          <FilterModal
            setFilterConfig={setFilterConfig}
            filterConfig={filterConfig}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            closeFilter={closeFilter}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            sortBy={sortBy}
            isMultiFilter
          />
        </ModalComponent>
        <DataTable
          isMultiFilter
          headCells={headCells}
          rows={studyData}
          nestedRowKey={Types.nestedRowTypes.STUDIES}
          nestedRowProps={nestedRowProps}
          toolbarProps={toolbarProps}
          isGlobalFilter
          enableCheckbox
          enableActions
          downloadStudyRow={downloadStudyRow}
          filterConfig={filterConfig}
          // handleSelectionClick={handleSelectionClick}
          maxHeight={580}
          filterKeys={['name', 'locationName', 'userName']}
          customToolbar={Types.toolbarTypes.STUDIES}
          defaultRowsPerPage={-1}
          handleRowClick={handleRowClick}
          filterColumns={filterConfig?.filterColumns}
          filterBy={filterConfig?.filterBy}
          sortOrder={filterConfig?.sortOrder}
          sortBy={filterConfig?.sortBy}
          hideCompactViewButton
          // onRowDelete={onDelete}
          setElementFilterConfig={setFilterConfig}
          // handleDragEnd={handleDragEnd}
          onEditClick={onEditClick}
          disableNestedRowClick
          hideNestedArrow
          // disableCheckbox
          isLoading={isLoading}
        />
      </Box>
      {/* ) : ( */}
      {singleStudy && <SingleStudy study={singleStudy} projectID={projectID} customerID={customerID} />}
      {/* )} */}
    </div>
  );
};

export default StudyPage;
StudyPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
