import { Box, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import DataTable from '../../components/table/VitrulisedTable';
import { getNotes } from './notesSlice';
import { Types } from '../../types';
import { ThemeButton } from '../../components/button';
import axiosInstance from '../../utils/axiosInstance';
import ModalComponent from '../../components/ModalComponent';
import FilterModal from '../admin/element/FilterModal';
import ThemeSwitch from '../../components/switch';

const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc'); // For handling UTC dates

dayjs.extend(utc);
dayjs.extend(duration);

const defaultNotePageColumnSettings = {
  studyType: true,
  studyName: true,
  formatedDate: true,
  locationName: true,
  taskName: true,
  elementName: true,
  observationStartTime: true,
  bms: true,
  notes: true,
  image: true,
};
const NotesPage = ({ projectID }) => {
  const { notes, isLoading } = useSelector((state) => state.notes);
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredColSettings, setFilteredColSettings] = useState(defaultNotePageColumnSettings);
  const [isPhotoDownloading, setPhotosDownloading] = useState([]);
  const [photoDownloadingId, setPhotosDownloadingId] = useState([]);
  const [isColumnsModalOpened, setColumnsModal] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const { singleProjectData } = useSelector((state) => state.project);
  const headCells = useMemo(
    () => [
      {
        id: 'studyType',
        disablePadding: false,
        label: 'Study Type',
        // minWidth: 180,
        renderElement: (cell) => (
          <Typography sx={{ color: getStudyColor(cell.studyType) }} variant="subtitle2">
            {getStudyLabel(cell.studyType)}
          </Typography>
        ),
        onClick: () => openFilter('studyType', 'studyType'),
        minWidth: 130,
      },

      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        onClick: () => openFilter('studyName', 'studyName'),
        minWidth: 130,
      },
      {
        id: 'isEc',
        // maxWidth: 36,
        align: 'left',
        renderElement: (cell) =>
          cell?.isEc ? (
            <Typography
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                ml: '15px',
                padding: '3px 6px',
                borderRadius: '21px',
                width: '27px',
              }}
              fontSize="small"
            >
              EC
            </Typography>
          ) : null,
      },
      {
        id: 'formatedDate',
        numeric: false,
        label: 'Time and Date',
        renderElement: (cell) => (
          <Stack>
            <Typography variant="subtitle2">{`${get(cell, 'formatedStartTime')} - ${get(cell, 'formatedEndTime')}`}</Typography>
            <Typography variant="subtitle2">{get(cell, 'formatedDate')}</Typography>
          </Stack>
        ),
        minWidth: 150,
        onClick: () => openFilter('formatedDate', 'formatedDate', false, 'date'),
      },
      {
        id: 'locationName',
        numeric: false,
        label: 'Location',
        onClick: () => openFilter('locationName', 'locationName'),
        // minWidth: 170,
        // onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'taskName',
        disablePadding: false,
        label: 'Task',
        onClick: () => openFilter('taskName', 'taskName'),
        // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 120,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              {/* <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} /> */}
              <Chip size="small" sx={{ minWidth: 70 }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
        // onClick: () => openFilter('bms', 'bms'),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        onClick: () => openFilter('notes', 'notes'),
        // minWidth: 250,
      },
      {
        id: 'image',
        numeric: false,
        label: 'Images',
        renderElement: (cell) =>
          cell?.photo ? (
            <ThemeButton
              loading={isPhotoDownloading && photoDownloadingId === cell?._id}
              onClick={() => downloadPhoto(cell)}
              variant="outlined"
              startIcon={<DownloadIcon />}
            >
              {getPhotoCount(cell)}
            </ThemeButton>
          ) : null,
      },
    ],
    [isPhotoDownloading, photoDownloadingId]
  );
  const openFilter = (key, orderBy, isLabelNumeric, sortType) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
    if (isLabelNumeric) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: true }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: false }));
    }
    if (sortType) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, sortType: 'string' }));
    }
  };
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  useEffect(() => {
    const notePageColumnSettings = JSON.parse(localStorage.getItem('notePageColumnSettings'));
    // The given code snippet checks whether there is any key in the notePageColumnSettings object that does not exist in the defaultNotePageColumnSettings object.
    const noteKeys = new Set(Object.keys(notePageColumnSettings));
    const defaultKeys = new Set(Object.keys(defaultNotePageColumnSettings));
    const gotDifferentKey =
      noteKeys.size !== defaultKeys.size ||
      [...noteKeys].some((key) => !defaultKeys.has(key)) ||
      [...defaultKeys].some((key) => !noteKeys.has(key));

    if (!notePageColumnSettings || gotDifferentKey) {
      localStorage.setItem('notePageColumnSettings', JSON.stringify(defaultNotePageColumnSettings));
    } else {
      setFilteredColSettings(notePageColumnSettings);
    }
  }, []);
  const filteredNoteHeadCells = useMemo(() => {
    const notePageColumnSettings = JSON.parse(localStorage.getItem('notePageColumnSettings'));
    if (!notePageColumnSettings) {
      localStorage.setItem('notePageColumnSettings', JSON.stringify(defaultNotePageColumnSettings));
      return headCells;
    }
    if (filteredColSettings) {
      return headCells.filter((cell) => filteredColSettings[cell?.id]);
    }
    return headCells;
  }, [filteredColSettings, headCells]);
  const downloadPhoto = async (cell) => {
    // Check if cell has a photo value
    if (cell?.photo) {
      try {
        // Split the photo URLs by comma, if there are multiple
        const photoUrls = cell.photo.split(',').map((url) => url.trim()); // Trim each URL
        setPhotosDownloading(true);
        setPhotosDownloadingId(() => cell?._id);
        // Use forEach to iterate through the URLs
        await Promise.all(
          photoUrls.map(async (url) => {
            try {
              // Make the API call for each photo URL
              const response = await axiosInstance({
                url: 'downloads/file',
                method: 'POST',
                data: {
                  filekey: url, // Use the URL in the API call
                  type: 'photo',
                },
                responseType: 'blob',
              });

              if (response.status !== 200) {
                console.log('Failed to fetch file for URL:', url);
                return; // Skip this photo and continue with the next one
              }

              // Create a temporary URL for the file
              const fileUrl = window.URL.createObjectURL(response.data);

              // Create an invisible <a> tag to trigger the download
              const link = document.createElement('a');
              link.href = fileUrl;
              link.download = url.split('/').pop(); // Use the filename from the URL as the download name
              document.body.appendChild(link);
              link.click();

              // Clean up: remove the <a> tag after the download
              document.body.removeChild(link);
              window.URL.revokeObjectURL(fileUrl); // Free up memory by revoking the object URL
            } catch (error) {
              console.log('Error downloading photo:', error);
            }
          })
        );
      } catch (error) {
        console.log('Error in processing photos:', error);
      } finally {
        setPhotosDownloading(false);
        setPhotosDownloadingId('');
      }
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const getPhotoCount = (cell) => {
    if (cell?.photo) {
      return cell?.photo?.split(',')?.length;
    }
    return '';
  };
  const fetchNotes = () => {
    if (projectID) {
      dispatch(getNotes({ projectID }));
    }
  };
  useEffect(() => {
    if (projectID) {
      // dispatch(getRoles({ projectID }));
      // dispatch(getAreas({ projectID }));
      // dispatch(getElements({ projectID, defaultEntry: false }));
      // dispatch(getTasks({ projectID }));
      dispatch(getNotes({ projectID }));
    }
  }, [dispatch, projectID]);
  const nestedRowProps = {
    fetchNotes,
    nestedType: 'nestedNotes',
  };

  // const { singleProjectData, isSingleProjectLoading, isAdding } = useSelector((state) => state.project);
  const { isSingleProjectLoading, isAdding } = useSelector((state) => state.project);

  const notesRows = useMemo(() => {
    if (notes && notes?.length > 0) {
      return notes?.map((note) => {
        // const formatedStartTime = dayjs(note?.startTime).format('HH:mm');
        // const formatedEndTime = dayjs(note?.endTime).format('HH:mm');
        // const formatedDate = dayjs(note?.startTime).format('DD/MM/YYYY');

        const startTime = dayjs(note?.startTime);
        const endtTime = dayjs(note?.endTime);
        const tz = get(note, 'studyTimezone') || get(note, 'projectTimezone') || singleProjectData?.timezone || 'Europe/London';
        const formatedStartTime = startTime.tz(tz)?.format('HH:mm');
        const formatedEndTime = endtTime.tz(tz)?.format('HH:mm');
        const formatedDate = startTime.tz(tz)?.format('DD/MM/YYYY');
        let formattedTime = '';
        let observationStartTime = '';
        if (note?.roundStartTime) {
          const dateTime = dayjs(note?.roundStartTime); // Ensure it's interpreted as UTC
          formattedTime = dateTime.format('HH:mm');
        }
        if (note?.startTime) {
          const dateTime = dayjs(note?.startTime).utc(); // Ensure it's interpreted as UTC
          // Format the time to hours and minutes
          const formattedStartTime = dateTime.format('HH:mm');
          // observationStartTime = formattedTime;
          observationStartTime = formattedStartTime;
        }
        const isEc = get(note, 'originalValues') && Object.keys(get(note, 'originalValues')).length > 0;
        return {
          ...note,
          startTime: formattedTime,
          bms: get(note, 'bms') === 0 ? '0' : get(note, 'bms') && parseFloat(get(note, 'bms').toFixed(2))?.toString(),
          frequency: get(note, 'frequency')?.toString(),
          bmsPerUom: get(note, 'bmsPerUom')?.toString(),
          _id: note?.observationID,
          roundID: note?.roundID,
          observationStartTime,
          formatedStartTime,
          formatedDate,
          formatedEndTime,
          isEc,
          taskName: get(note, 'taskName') || '',
          notes: get(note, 'notes') || '',
          locationName: get(note, 'locationName') || '',
          elementName: get(note, 'elementName') || '',
        };
      });
    }
    return [];
  }, [notes, singleProjectData?.timezone]);
  const elementOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueElements = [...new Set(notesRows.map((studyRow) => studyRow.elementName))];
      return uniqueElements
        .sort((a, b) => a.localeCompare(b))
        .map((elementName) => (elementName ? { label: elementName, value: elementName } : { label: 'No Element', value: '' }));
    }
    return [];
  }, [notesRows]);
  const locationOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueElements = [...new Set(notesRows.map((studyRow) => studyRow.locationName))];
      return uniqueElements
        .sort((a, b) => a.localeCompare(b))
        .map((elementName) => (elementName ? { label: elementName, value: elementName } : { label: 'No Location', value: '' }));
    }
    return [];
  }, [notesRows]);
  const dateFilterOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueElements = [...new Set(notesRows.map((studyRow) => studyRow.formatedDate))];
      return uniqueElements.sort((a, b) => a.localeCompare(b)).map((elementName) => ({ label: elementName, value: elementName }));
    }
    return [];
  }, [notesRows]);
  const studynameOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const studyElements = [...new Set(notesRows.map((studyRow) => studyRow.studyName))];
      return studyElements.sort((a, b) => a.localeCompare(b)).map((studyName) => ({ label: studyName, value: studyName }));
    }
    return [];
  }, [notesRows]);
  const taskOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueTaskNames = [...new Set(notesRows.map((studyRow) => studyRow.taskName))];
      return (
        uniqueTaskNames
          // ?.filter((currentTaskName) => !!currentTaskName)
          ?.sort((a, b) => a.localeCompare(b))
          .map((taskName) => (taskName ? { label: taskName, value: taskName } : { label: 'No Task', value: '' }))
      );
    }
    return [];
  }, [notesRows]);
  const observationStartTimeOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueTaskNames = [...new Set(notesRows.map((studyRow) => studyRow.observationStartTime))];
      return uniqueTaskNames.sort((a, b) => a.localeCompare(b)).map((taskName) => ({ label: taskName, value: taskName }));
    }
    return [];
  }, [notesRows]);
  const notesOptions = useMemo(() => {
    if (notesRows && notesRows?.length) {
      const uniqueAreaNames = [...new Set(notesRows.map((studyRow) => studyRow.notes))];
      return (
        uniqueAreaNames
          // .filter((note) => !!note)
          .sort((a, b) => a.localeCompare(b))
          .map((note) => (note ? { label: note, value: note } : { label: 'No Note', value: '' }))
      );
    }
    return [];
  }, [notesRows]);
  const studyOptions = useMemo(
    () => [
      { label: 'Activity Study', value: 3 },
      { label: 'Efficiency Study', value: 1 },
      { label: 'Role Study', value: 2 },
      // { label: 'Predetermined Study', value: 4 },
    ],
    []
  );
  const isCellVisible = (cell) => (filteredColSettings ? filteredColSettings[cell?.id] : false);
  useEffect(() => {
    switch (filterBy) {
      case 'elementName':
        setFilterOptions(elementOptions);
        break;
      case 'studyName':
        setFilterOptions(studynameOptions);
        break;
      case 'taskName':
        setFilterOptions(taskOptions);
        break;
      case 'locationName':
        setFilterOptions(locationOptions);
        break;
      case 'formatedDate':
        setFilterOptions(dateFilterOptions);
        break;
      case 'notes':
        setFilterOptions(notesOptions);
        break;
      case 'observationStartTime':
        setFilterOptions(observationStartTimeOptions);
        break;
      case 'studyType':
        setFilterOptions(studyOptions);
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    dateFilterOptions,
    elementOptions,
    filterBy,
    locationOptions,
    notesOptions,
    observationStartTimeOptions,
    studyOptions,
    studynameOptions,
    taskOptions,
  ]);
  const handleColumnChange = (event, cell) => {
    const newColSettings = {
      ...filteredColSettings,
      [cell?.id]: event.target.checked,
    };
    setFilteredColSettings((prevSettings) => newColSettings);
    localStorage.setItem('notePageColumnSettings', JSON.stringify(newColSettings));
  };
  const toolbarProps = {
    setFilterConfig,
  };
  return (
    <div>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Filter"
        open={isFilterOpen}
        onClose={setIsFilterOpen}
      >
        <FilterModal
          setFilterConfig={setFilterConfig}
          filterConfig={filterConfig}
          setFilterBy={setFilterBy}
          filterOptions={filterOptions}
          closeFilter={closeFilter}
          filterBy={filterBy}
          sortBy={sortBy}
        />
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Hide/Show Columns"
        open={isColumnsModalOpened}
        onClose={setColumnsModal}
        closeButton
      >
        <Box>
          {headCells?.map(
            (cell) =>
              cell?.id !== 'isEc' && (
                <ThemeSwitch
                  label={cell?.label}
                  onChange={(event) => handleColumnChange(event, cell)}
                  checked={isCellVisible(cell)}
                />
              )
          )}
        </Box>
      </ModalComponent>
      <DataTable
        filterKeys={['taskName', 'elementName', 'studyName', 'locationName', 'notes', 'formatedDate']}
        customToolbar={Types.toolbarTypes.NOTES}
        headCells={filteredNoteHeadCells}
        rows={notesRows}
        filterColumns={filterConfig?.filterColumns}
        filterBy={filterConfig?.filterBy}
        sortOrder={filterConfig?.sortOrder}
        sortBy={filterConfig?.sortBy}
        setElementFilterConfig={setFilterConfig}
        enableActions
        // customColumnType={study?.studyType}
        nestedRowKey={Types.nestedRowTypes.NEST_ROW}
        nestedRowProps={nestedRowProps}
        customColumnType="studyType"
        disableNestedRowClick
        hideNestedArrow
        onEditClick={onEditClick}
        isLoading={isLoading}
        filterConfig={filterConfig}
        toolbarProps={toolbarProps}
        // onRowDelete={onDelete}
        enableDelete
        defaultRowsPerPage={-1}
        maxHeight={500}
        hideCompactViewButton
        disableCheckbox
        onSettingsClick={() => setColumnsModal(true)}
      />
    </div>
  );
};

NotesPage.propTypes = { projectID: PropTypes.string };

export default NotesPage;
