/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  alpha,
  Box,
  CircularProgress,
  Container,
  DialogActions,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { get, has, isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import ModalComponent from '../../../components/ModalComponent';
import StudiesComponent from '../../../components/StudiesComponent';
import { ActionButton, ThemeButton } from '../../../components/button';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import ThemeSwitch from '../../../components/switch';
import DataTable from '../../../components/table';
import { toggleDownload } from '../../../layout/dashboard/dashboardSlice';
import { Types } from '../../../types';
import { getRatingLabel, getTypeLabel } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { addElementSchema } from '../../../utils/schema';
import { getCustomers } from '../../customer/customerSlice';
import DownloadComponent from '../../projectList/DownloadComponent';
import { getCategories } from '../category/categoriesSlice';
import FilterModal from './FilterModal';
import { addElement, editElement, exportElement, getElements } from './elementsSlice';

const studyOptions = [
  { label: 'Activity Study', value: 3 },
  { label: 'Efficiency Study', value: 1 },
  { label: 'Role Study', value: 2 },
  // { label: 'Predetermined Study', value: 4 },
];
const typeOptions = [
  { label: 'Fixed', value: 1 },
  { label: 'Variable', value: 2 },
];
const ratingOptions = [
  {
    label: 'Not Rated',
    description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
    value: 1,
  },
  { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
  { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
];
const statusOptions = [
  { label: 'Active', value: Types.ACTIVE },
  { label: 'Archive', value: Types.ARCHIVED },
];
const ElementsListPage = () => {
  const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const openFilter = (key, orderBy) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
  };
  const headCells = [
    {
      id: 'name',
      label: 'Description',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('name', 'name'),
    },
    {
      id: 'typeLabel',
      label: 'Type',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('type', 'typeLabel'),
    },
    {
      id: 'count',
      label: 'Count',
      align: 'left',
      // minWidth: 200,
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.count') && get(cell, 'projectSetting.count') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="error" />
        ),
      // titleCase: true,
    },
    {
      // id: '',
      label: 'Studies ',
      align: 'center',
      // minWidth: '360px',
      maxWidth: '240px',
      renderElement: (cell) => <StudiesComponent studyTypes={get(cell, 'projectSetting.studyTypes')} />,
      titleCase: true,
      onClick: () => openFilter('studyTypes', 'studyTypes'),
    },
    {
      id: 'category',
      label: 'Category ',
      align: 'left',
      // minWidth: 200,
      // titleCase: true,
      onClick: () => openFilter('categoryID', 'category'),
    },
    {
      id: 'relaxationAllowancePercent',
      label: 'R.A ',
      align: 'left',
      // minWidth: 200,
    },
    {
      id: 'contingencyAllowancePercent',
      label: 'C.A ',
      align: 'left',
      // minWidth: 200,
    },

    {
      id: 'ratingLabel',
      label: 'Rating ',
      align: 'left',
      // minWidth: 200,
      titleCase: true,
      onClick: () => openFilter('rating', 'ratingLabel'),
    },
    // {
    //   id: 'analysisLabel',
    //   label: 'Customer  ',
    //   align: 'left',
    // minWidth: 200,
    //   titleCase: true,
    // },
    // {
    //   id: 'userAdded',
    //   label: 'User Added   ',
    //   renderElement: (cell) =>
    //     !isUndefined(cell, 'projectSetting.userAdded') &&
    //     get(cell, 'projectSetting.userAdded') && (
    //       <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
    //     ),
    //   align: 'left',
    //   // minWidth: 200,
    //   titleCase: true,
    // },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      // width: '15%',
      onClick: () => openFilter('status', 'status'),
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  ];
  const form = useForm({
    defaultValues: {
      defaultEntry: true,
      name: '',
      unitOfMeasure: '',
      studyTypes: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
    },
    resolver: yupResolver(addElementSchema),
  });
  const { register, handleSubmit, formState, reset, setValue, control } = form;
  const { errors } = formState;

  const [isModalopen, setisModalopen] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [ElementRows, setelementRows] = useState([]);
  const { elements, isLoading, isAdding, isElementexporting } = useSelector((state) => state.element);
  const { categories } = useSelector((state) => state.category);
  const { customers } = useSelector((state) => state.customer);
  useEffect(() => {
    dispatch(getElements({ defaultEntry: true }));
    dispatch(getCategories({ defaultEntry: true }));
    dispatch(getCustomers());
  }, [dispatch]);

  //  studies options, customerOptions, statusOptions
  const regularElements = useMemo(
    () =>
      !isEmpty(elements) &&
      elements.reduce(
        (acc, element) => {
          const fullElement = {
            ...element,
            name: get(element, 'name'),
            type: get(element, 'projectSetting.type'),
            count: get(element, 'projectSetting.count'),
            categoryID: get(element, 'projectSetting.categoryID'),
            customerID: get(element, 'projectSetting.customerID'),
            studyTypes: get(element, 'projectSetting.studyTypes', []),
            status: get(element, 'projectSetting.status'),
            userAdded: get(element, 'projectSetting.userAdded', false),
            typeLabel: getTypeLabel(get(element, 'projectSetting.type')),
            relaxationAllowancePercent: get(element, 'projectSetting.relaxationAllowance')
              ? `${element.projectSetting.relaxationAllowance} %`
              : '0%',
            contingencyAllowancePercent: get(element, 'projectSetting.contingencyAllowance')
              ? `${element.projectSetting.contingencyAllowance} %`
              : '0%',
            category:
              !!categories?.length &&
              categories?.find((category) => category?._id === get(element, 'projectSetting.categoryID'))?.name,
            customer:
              !!customers?.length &&
              customers?.find((customer) => customer?._id === get(element, 'projectSetting.customerID'))?.name,
            ratingLabel: getRatingLabel(get(element, 'projectSetting.rating')),
            rating: get(element, 'projectSetting.rating'),
          };
          if (element?.name) {
            const descriptionOption = { label: element?.name, value: element?.name?.toLowerCase() };
            acc.descriptionOptions.push(descriptionOption);
          }

          if (element?.projectSetting?.status === 'active') {
            acc.active.push(fullElement);
          } else if (element?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullElement);
          }
          acc.default.push(fullElement);
          return acc;
        },
        { active: [], archive: [], default: [], descriptionOptions: [] }
      ),
    [categories, elements, customers]
  );

  const customerOptions = useMemo(() => {
    if (customers?.length) {
      return customers?.map((customer) => ({ label: customer?.name, value: customer?._id }));
    }
    return [];
  }, [customers]);

  const categoryOptions = useMemo(
    () =>
      categories
        .filter((category) => has(category, 'projectSetting.status') && category.projectSetting.status === 'active')
        .map((category) => ({ label: category.name, value: category._id })),
    [categories]
  );
  const { isDownloadOpen } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (regularElements) {
      if (Status === DEFAULT) {
        setelementRows(regularElements?.default);
      } else if (Status === ARCHIVED) {
        setelementRows(regularElements?.archive);
      } else if (Status === ACTIVE) {
        setelementRows(regularElements?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularElements]);
  useEffect(() => {
    switch (filterBy) {
      case 'name':
        setFilterOptions(regularElements.descriptionOptions);
        break;
      case 'customerID':
        setFilterOptions(customerOptions);
        break;
      case 'categoryID':
        setFilterOptions(categoryOptions);
        break;
      case 'studyTypes':
        setFilterOptions(studyOptions);
        break;
      case 'rating':
        setFilterOptions(ratingOptions);
        break;
      case 'type':
        setFilterOptions(() =>
          typeOptions && regularElements?.default?.length > 0
            ? typeOptions?.filter((option) =>
                regularElements?.default?.some((element) => element?.projectSetting?.type === option?.value)
              )
            : []
        );
        break;
      case 'status':
        setFilterOptions(statusOptions);
        break;

      default:
        setFilterOptions([]);
        break;
    }
  }, [categoryOptions, customerOptions, filterBy, regularElements?.default, regularElements.descriptionOptions]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setelementRows(regularElements.default);
      } else if (value === ACTIVE) {
        setelementRows(regularElements.active);
      } else if (value === ARCHIVED) {
        setelementRows(regularElements.archive);
      }
    }
  };
  const getStatusPayload = (selectedIds, status) => {
    const fetchUrl = 'elements/update/status';
    const payload = {
      defaultEntry: true,
      elementIDs: selectedIds,
      status,
    };
    return {
      fetchUrl,
      payload,
    };
  };
  const getRemovePayload = (selectedIds) => {
    const deleteUrl = `elements/delete/${selectedIds[0]}`;
    const deletePayload = {
      elementIDs: selectedIds,
      defaultEntry: true,
    };
    return {
      deleteUrl,
      deletePayload,
    };
  };
  const fetchData = async () => dispatch(getElements({ defaultEntry: true }));
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Element',
    getStatusPayload,
    fetchData,
    getRemovePayload,
  };

  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset();
    // reset({ permission: '' });
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const openModal = () => setisModalopen(true);
  const onSubmit = (values) => {
    if (values) {
      if (get(values, 'rating')) {
        values.rating = parseInt(values.rating, 10);
      }
      if (get(values, 'type')) {
        values.type = parseInt(values.type, 10);
      }

      if (rowToEdit) {
        dispatch(editElement({ ...values, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            dispatch(getElements({ defaultEntry: true }));
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addElement(values))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                })
              );
              dispatch(getElements({ defaultEntry: true }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `elements/get/${row?._id}`,
          method: 'POST',
          data: { defaultEntry: true },
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
          setValue('categoryID', get(response, 'data.data.projectSetting.categoryID', ''));
          if (get(response, 'data.data.projectSetting.unitOfMeasure')) {
            setValue('unitOfMeasure', get(response, 'data.data.projectSetting.unitOfMeasure', ''));
          }
          if (get(response, 'data.data.projectSetting.rating')) {
            setValue('rating', get(response, 'data.data.projectSetting.rating', ''));
          }
          if (get(response, 'data.data.projectSetting.studyTypes')) {
            setValue('studyTypes', get(response, 'data.data.projectSetting.studyTypes', ''));
          }
          if (
            get(response, 'data.data.projectSetting.contingencyAllowance') ||
            get(response, 'data.data.projectSetting.contingencyAllowance') === 0
          ) {
            setValue('contingencyAllowance', get(response, 'data.data.projectSetting.contingencyAllowance'));
          }
          if (
            get(response, 'data.data.projectSetting.relaxationAllowance') ||
            get(response, 'data.data.projectSetting.relaxationAllowance') === 0
          ) {
            setValue('relaxationAllowance', get(response, 'data.data.projectSetting.relaxationAllowance'));
          }
          if (get(response, 'data.data.projectSetting.count')) {
            setValue('count', get(response, 'data.data.projectSetting.count', ''));
          }
          if (get(response, 'data.data.projectSetting.type')) {
            setValue('type', get(response, 'data.data.projectSetting.type', ''));
          }
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'elements/update/status',
          method: 'PATCH',
          data: { elementIDs: [row?._id], status, defaultEntry: true },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Element`,
            })
          );
          dispatch(getElements({ defaultEntry: true }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `elements/delete/${row?._id}`,
          method: 'DELETE',
          data: { defaultEntry: true, projectID: row?.projectSetting?.projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getElements({ defaultEntry: true }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const handleExportElement = async () => {
    dispatch(
      exportElement({
        defaultEntry: true,
      })
    )
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const toggleDrawer = () => dispatch(toggleDownload());
  return (
    <Container maxWidth="xxl">
      <Drawer sx={{ position: 'unset' }} hideBackdrop anchor="right" open={isDownloadOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            px: 1,
            // borderRight: '1px solid #ececec',
            pl: 0,
            display: 'flex',
            width: 600,
            bgcolor: '#f1f1f1',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <DownloadComponent />
        </Box>
      </Drawer>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Filter"
        open={isFilterOpen}
        onClose={setIsFilterOpen}
      >
        <FilterModal
          setFilterConfig={setFilterConfig}
          filterConfig={filterConfig}
          filterOptions={filterOptions}
          closeFilter={closeFilter}
          setFilterBy={setFilterBy}
          filterBy={filterBy}
          sortBy={sortBy}
        />
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1200px !important',
          },
        }}
        title={rowToEdit ? 'Edit Element' : 'Create Element'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid className="left-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={12}>
                  <FormInput
                    width="90%"
                    label="Name"
                    placeholder="e.g Greet Customer"
                    InputProps={{
                      ...register('name'),
                    }}
                    error={!!errors?.name}
                    helperText={errors?.name && errors?.name?.message}
                  />
                </Grid>
                <Grid className="type" item xs={12} md={12}>
                  <Box
                    sx={{
                      // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      mt: 1,
                    }}
                  >
                    <GroupCheckbox error={!!errors?.type} helperText={errors?.type && errors?.type?.message} label="Type">
                      <FormRadioGroup row fontSize={18} options={typeOptions} control={control} name="type" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
                <Grid className="study-types" item xs={12} md={12}>
                  <CheckboxGroup
                    row
                    control={control}
                    label="Study Types"
                    name="studyTypes"
                    options={studyTypeOptions}
                    config={studyTypeConfig}
                  />
                </Grid>
                <Grid className="rating" item xs={12} md={12}>
                  <Box
                    sx={{
                      '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      width: '80%',
                    }}
                  >
                    <GroupCheckbox
                      error={!!errors?.rating}
                      helperText={errors?.rating && errors?.rating?.message}
                      label="Default Rating"
                    >
                      <FormRadioGroup fontSize={18} options={ratingOptions} control={control} name="rating" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="right-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={2} columnSpacing={1} container>
                <Grid className="count" item xs={12} md={12}>
                  <ThemeSwitch
                    description="Relevant only on Role and Activity studies"
                    label="Count"
                    control={control}
                    name="count"
                    width="90%"
                  />
                </Grid>
                <Grid className="unit-of-measures" item xs={12} md={12}>
                  <FormInput
                    label="Unit of Measure"
                    placeholder="e.g Per item"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('unitOfMeasure'),
                    }}
                    width="90%"
                    error={!!errors?.unitOfMeasure}
                    helperText={errors?.unitOfMeasure && errors?.unitOfMeasure?.message}
                  />
                </Grid>
                <Grid className="category" item lg={12} sm={12} xs={12} md={12}>
                  <label id="category-select" style={{ fontSize: '0.8600rem' }}>
                    Category
                  </label>
                  <CustomSelect
                    width="95%"
                    name="categoryID"
                    // InputProps={{ ...register('customerID') }}
                    control={control}
                    options={categoryOptions}
                    label="Select a category"
                    // value={watch('customerID')}
                    error={!!errors?.categoryID}
                    helperText={errors?.categoryID && errors?.categoryID?.message}
                    // placeholder=Chooseexpiration date"
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    type="number"
                    label="Relaxation Allowance"
                    placeholder="e.g 1"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('relaxationAllowance'),
                    }}
                    width="90%"
                    error={!!errors?.relaxationAllowance}
                    helperText={errors?.relaxationAllowance && errors?.relaxationAllowance?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormInput
                    type="number"
                    label="Contingency Allowance"
                    placeholder="e.g 1"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('contingencyAllowance'),
                    }}
                    width="90%"
                    error={!!errors?.contingencyAllowance}
                    helperText={errors?.contingencyAllowance && errors?.contingencyAllowance?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Element'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h4">Elements</Typography>
        </Grid>
        <Grid item sm={6}>
          <Stack mb direction="row" alignItems="flex-start" justifyContent="flex-end" gap>
            {/* <Stack direction="column" alignItems="center">
              <IconButton
                disabled={isElementexporting}
                onClick={handleExportElement}
                sx={{ bgcolor: (theme) => alpha(theme.palette.primary.main, 0.4) }}
              >
                {isElementexporting ? <CircularProgress color="inherit" size={24} /> : <FileDownloadOutlinedIcon />}
              </IconButton>
              <Typography variant="body2">Export Element</Typography>
            </Stack> */}
            <ActionButton
              loading={isElementexporting}
              onClick={handleExportElement}
              size="small"
              tooltip="Download System Elements"
              // startIcon={<FileDownloadOutlinedIcon />}
              variant="outlined"
            >
              <FileDownloadOutlinedIcon />
            </ActionButton>
            <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
              Create Element
            </ThemeButton>
            <ThemeButton
              // fullWidth
              startIcon={<FileDownloadOutlinedIcon />}
              variant="contained"
              color="success"
              // size="small"
              onClick={toggleDrawer}
            >
              Downloads
            </ThemeButton>
          </Stack>
        </Grid>
      </Grid>
      {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Elements</Typography>
        <Stack width="100%" bgcolor="red" direction="row" alignItems="flex-start" gap>
          <Stack direction="column" alignItems="center">
            <IconButton
              disabled={isElementexporting}
              onClick={handleExportElement}
              sx={{ bgcolor: (theme) => alpha(theme.palette.primary.main, 0.4) }}
            >
              {isElementexporting ? <CircularProgress color="inherit" size={24} /> : <FileDownloadOutlinedIcon />}
            </IconButton>
            <Typography variant="body2">Export Element</Typography>
          </Stack>
          <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
            Create Element
          </ThemeButton>
          <ThemeButton
            fullWidth
            startIcon={<FileDownloadOutlinedIcon />}
            variant="contained"
            color="success"
            size="small"
            onClick={toggleDrawer}
          >
            Downloads
          </ThemeButton>
        </Stack>
      </Box> */}
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['name']}
        filterConfig={filterConfig}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        // handleSelectionClick={handleSelectionClick}
        filterColumns={filterConfig?.filterColumns}
        filterBy={filterConfig?.filterBy}
        sortOrder={filterConfig?.sortOrder}
        sortBy={filterConfig?.sortBy}
        rows={ElementRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default ElementsListPage;
