import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
import { updateObservation } from '../../studiesPage/studiesSlice';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  obsLoading: false,
  dataSummaries: [],
  obsCount: 0,
};
export const getDataSummaries = createAsyncThunk('dataSummaries/getdataSummaries', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'studies/get/summary',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getObservationCount = createAsyncThunk('dataSummaries/getdataSummaruCount', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'studies/get/obs/count',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const dataSummarySlice = createSlice({
  name: 'dataSummaries',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDataSummaries.pending, (state, action) => {
      state.isLoading = true;
      state.dataSummaries = [];
    });
    builder.addCase(getDataSummaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataSummaries = action.payload?.data;
    });
    builder.addCase(getDataSummaries.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getObservationCount.pending, (state, action) => {
      state.obsLoading = true;
      state.obsCount = 0;
    });
    builder.addCase(getObservationCount.fulfilled, (state, action) => {
      state.obsLoading = false;
      state.obsCount = action.payload?.data;
    });
    builder.addCase(getObservationCount.rejected, (state, action) => {
      state.obsLoading = false;
    });
    builder.addCase(updateObservation.fulfilled, (state, action) => {
      if (action.payload?.values) {
        if (action.payload.values?.studyType === 1) {
          if (state.dataSummaries?.length > 0) {
            state.dataSummaries.forEach((observation) => {
              if (observation.observationID === action.payload.values._id) {
                observation.frequency = get(action, 'payload.values.frequency');
                observation.areaID = get(action, 'payload.values.areaID');
                observation.roleID = get(action, 'payload.values.roleID');
                observation.rating = get(action, 'payload.values.rating');
                observation.elementID = get(action, 'payload.values.elementID');
                observation.notes = get(action, 'payload.values.notes');
                observation.elementName = get(action, 'payload.values.elementName');
                observation.areaName = get(action, 'payload.values.areaName');
                observation.roleName = get(action, 'payload.values.roleName');
              }
            });
          }
        } else if (state.dataSummaries?.length > 0) {
          state.dataSummaries.forEach((observation) => {
            if (observation.observationID === action.payload.values._id) {
              observation.frequency = get(action, 'payload.values.frequency');
              observation.areaID = get(action, 'payload.values.areaID');
              observation.taskID = get(action, 'payload.values.taskID');
              observation.rating = get(action, 'payload.values.rating');
              observation.elementID = get(action, 'payload.values.elementID');
              observation.notes = get(action, 'payload.values.notes');
              observation.elementName = get(action, 'payload.values.elementName');
              observation.areaName = get(action, 'payload.values.areaName');
              observation.taskName = get(action, 'payload.values.taskName');
            }
          });
        }
      }
    });
  },
  reducers: {
    updateSummary: (state, action) => {
      if (state?.dataSummaries?.length > 0) {
        state.dataSummaries.forEach((summary) => {
          const reqObs = action?.payload?.observations?.find((obs) => obs?.observationID === summary?.observationID);
          if (reqObs) {
            if (action?.payload?.studyType === 1) {
              summary.frequency = get(reqObs, 'frequency');
              summary.areaID = get(reqObs, 'areaID');
              summary.roleID = get(reqObs, 'roleID');
              summary.rating = get(reqObs, 'rating');
              summary.elementID = get(reqObs, 'elementID');
              summary.notes = get(reqObs, 'notes');
              summary.elementName = get(reqObs, 'elementName');
              summary.areaName = get(reqObs, 'areaName');
              summary.roleName = get(reqObs, 'roleName');
            } else {
              summary.frequency = get(reqObs, 'frequency');
              summary.areaID = get(reqObs, 'areaID');
              summary.taskID = get(reqObs, 'taskID');
              summary.rating = get(reqObs, 'rating');
              summary.elementID = get(reqObs, 'elementID');
              summary.notes = get(reqObs, 'notes');
              summary.elementName = get(reqObs, 'elementName');
              summary.areaName = get(reqObs, 'areaName');
              summary.taskName = get(reqObs, 'taskName');
            }
          }
        });
      }
    },
  },
});
export const { updateSummary } = dataSummarySlice.actions;
export default dataSummarySlice.reducer;
